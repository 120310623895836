import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./../components/Layout";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import PasswordResetSentMessage from "../pages/PasswordResetSentMessage";
import ResetPassword from "../pages/ResetPassword";
import Overview from "../pages/Overview";
import Applications from "../pages/Applications";
import { PrivateRoute } from "./PrivateRoute";
import UniquesNumbers from "../pages/UniquesNumbers";
import Evaluations from "../pages/Evaluations";

const routes = {
  public: [
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "/password-reset/sent", element: <PasswordResetSentMessage /> },
    { path: "/reset-password/", element: <ResetPassword /> },
  ],
  private: [
    { path: "/overview", element: <Overview /> },
    { path: "/applicants", element: <Applications /> },
    { path: "/unique-numbers", element: <UniquesNumbers /> },
    { path: "/evaluations", element: <Evaluations /> },
  ],
};

const AllRoutes = () => {
  const adminType = localStorage.getItem("adminType") ?? "";
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        {routes.public.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
        <Route element={<Layout />}>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Navigate
                  to={
                    adminType === "Invigilators" ? "/evaluations" : "/overview"
                  }
                  replace
                />
              </PrivateRoute>
            }
          />
          {routes?.private?.map((route, index) => {
            return (
              <Route
                key={index}
                path={route?.path}
                index={index}
                element={<PrivateRoute>{route.element}</PrivateRoute>}
              />
            );
          })}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;
