import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import QuestionAnswer from "./QuestionAnswer";
import FilterByStatus from "../uiComponents/FilterByStatus";
import { postEvaluationForm } from "../services/user";
import { toast } from "react-toastify";

const options = [
  {
    label: "strongly recommend",
    value: "strongly recommend",
  },
  {
    label: "recommend with reservations",
    value: "recommend with reservations",
  },
  {
    label: "reject",
    value: "reject",
  },
];

const initialData = () => {
  return {
    resilient_optimism: "",
    diligence_determination: "",
    longterm_passion: "",
    focus: "",
    risk_tolerance: "",
    internal_locus_control: "",
    achievement_need: "",
    resourcefulness: "",
    growth_mindset: "",
    experimental_mindset: "",
    bootstrap_funding: "",
    curiosity: "",
    founder_fit: "",
    passion: "",
    product_market_fit: "",
    overall_impression: "",
    final_recommendation: "",
  };
};

const EvaluationForm = ({ id }) => {
  const [evaluationForm, setEvaluationForm] = useState(initialData());

  const handleChange = (key, value) => {
    setEvaluationForm((pre) => {
      return {
        ...pre,
        [key]: value,
      };
    });
  };

  const handleSubmit = async () => {
    const adminId = localStorage.getItem("adminId") ?? "";
    const request = {
      user_id: id,
      admin_id: adminId,
      feedback_data: evaluationForm,
    };

    const data = Object.values(evaluationForm).some((value) => value === "");
    if (!data) {
      try {
        const resp = await postEvaluationForm(request);
        toast.success("Evaluation Form Submitted Successfully!");
      } catch (e) {
        console.log(
          "error while fetching post evaluation form Data API:=",
          e?.message
        );
      }
    } else {
      toast.error("All fields are mandatory");
    }
  };

  return (
    <Box>
      <Box fontSize="20px" fontWeight={700} m="26px 0px 15px 0px">
        Please score for the attributes
      </Box>
      <Box display={"grid"} gridTemplateColumns={"repeat(2,1fr)"} gap="12px">
        <QuestionAnswer
          question={"Resilient Optimism"}
          value={evaluationForm.resilient_optimism}
          onChange={(value) => handleChange("resilient_optimism", value)}
          text={
            "Combines maintaining a positive, hopeful outlook with the grit and resilience required to advance despite challenges or setbacks. This trait embodies the ability to see growth opportunities in the face of difficulties, persisting toward goals with an enthusiastic and optimistic demeanor."
          }
        />
        <QuestionAnswer
          question={"Diligence and Determination"}
          value={evaluationForm.diligence_determination}
          onChange={(value) => handleChange("diligence_determination", value)}
          text={
            "This trait is about a persistent work ethic and carefulness with a firmness of purpose in carrying out tasks. Entrepreneurs who exhibit both diligence and determination pay close attention to details, maintain a strong drive to achieve their goals, and show unwavering commitment to their business, irrespective of the obstacles they face."
          }
        />
        <QuestionAnswer
          question={"Long-term Passion Vs Fickle Mindedness"}
          value={evaluationForm.longterm_passion}
          onChange={(value) => handleChange("longterm_passion", value)}
          text={
            " This trait combines the issues faced by someone who might change business ideas, strategies, or directions without a clear or consistent rationale (fickle-minded) with the powerful drive of someone exhibiting sustained enthusiasm and commitment to a business or cause (long-term passion). These individuals struggle to balance their frequent changes in focus and direction with a sustained commitment to the overarching goals of their business, making it crucial to evaluate their ability to merge adaptability with unwavering dedication for long-term success."
          }
        />
        <QuestionAnswer
          question={"Focus"}
          value={evaluationForm.focus}
          onChange={(value) => handleChange("focus", value)}
          text={
            "Reflects an entrepreneur's discipline to focus on what truly matters, and the wisdom to make choices that align with their long-term vision rather than immediate gratification or distractions. This attribute underscores the importance of focused execution in the entrepreneurial journey."
          }
        />
        <QuestionAnswer
          question={"Risk Tolerance"}
          value={evaluationForm.risk_tolerance}
          onChange={(value) => handleChange("risk_tolerance", value)}
          text={
            "This is the willingness to take calculated risks. Successful entrepreneurs are able to assess situations, weigh potential gains against losses, and take risks when they believe the outcome could be beneficial for their business."
          }
        />
        <QuestionAnswer
          question={"Internal Locus of Control"}
          value={evaluationForm.internal_locus_control}
          onChange={(value) => handleChange("internal_locus_control", value)}
          text={
            "This trait is about believing you have control over your own success or failure rather than attributing outcomes to external factors. Entrepreneurs with an internal locus of control are more likely to take responsibility for their actions and decisions, driving them toward proactive problem-solving and persistence."
          }
        />
        <QuestionAnswer
          question={"Need For Achievement "}
          value={evaluationForm.achievement_need}
          onChange={(value) => handleChange("achievement_need", value)}
          text={
            " This refers to an individual's drive to accomplish goals and succeed. Entrepreneurs with a high need for achievement are often motivated by setting challenging targets and thriving on overcoming them."
          }
        />
        <QuestionAnswer
          question={"Resourcefulness"}
          value={evaluationForm.resourcefulness}
          onChange={(value) => handleChange("resourcefulness", value)}
          text={
            "This is the ability to find quick and clever ways to overcome difficulties. Entrepreneurs need to be resourceful, especially in the early stages of their ventures, finding innovative solutions with limited resources."
          }
        />
        <QuestionAnswer
          question={"Growth Mindset"}
          value={evaluationForm.growth_mindset}
          onChange={(value) => handleChange("growth_mindset", value)}
          text={
            "Individuals with a growth mindset believe their abilities and intelligence can be developed with effort, learning, and persistence. Entrepreneurs who embrace a growth mindset are more likely to embrace challenges, learn from criticism, and find inspiration in the success of others."
          }
        />
        <QuestionAnswer
          question={"Experimental Mindset"}
          value={evaluationForm.experimental_mindset}
          onChange={(value) => handleChange("experimental_mindset", value)}
          text={
            "Refers to an individual's inclination and ability to explore new ideas, test hypotheses, and embrace a hands-on approach to problem-solving. Those with an experimental mindset typically prioritize learning through practical experiences and iteration."
          }
        />
        <QuestionAnswer
          question={"Bootstrap Vs Funding"}
          value={evaluationForm.bootstrap_funding}
          onChange={(value) => handleChange("bootstrap_funding", value)}
          text={
            "We want to understand the mindset of the candidate with respect to funding their business venture. We are looking for candidates who will not wait for some VC to fund their business idea, but someone who has a go getter attitude and is willing to take risks, lose their own money, figure out unique ways to fund the business venture. VC money should be the last thing on their mind."
          }
        />
        <QuestionAnswer
          question={"Curiosity"}
          value={evaluationForm.curiosity}
          onChange={(value) => handleChange("curiosity", value)}
          text={
            "A curious mind is one which is always aware of it's surrounding while very optimistic about the future. They are the ones who understand trends and future roadmap. If a problem is not solved, it causes stress. A new challenge is a good thing for them. uriosity can be defined as a relentless drive to explore, understand, and learn about various aspects of the business environment. It involves a genuine interest in discovering new opportunities, understanding customer needs, analyzing market trends, and seeking innovative solutions to problems. Curiosity in entrepreneurship fuels experimentation, encourages risk-taking, and drives continuous improvement. Entrepreneurs who possess a high level of curiosity are often more adaptable, open-minded, and willing to challenge the status quo, which can lead to the creation of unique products or services and the development of successful businesses."
          }
        />
      </Box>
      <Box display={"grid"} gridTemplateColumns={"repeat(2,1fr)"} gap="20px">
        <Box>
          <Box fontSize="20px" fontWeight={700} m="26px 0px 15px 0px">
            Founder-Product Fit
          </Box>
          <TextField
            multiline
            rows={4}
            fullWidth
            value={evaluationForm.founder_fit}
            onChange={(e) => handleChange("founder_fit", e.target.value)}
          />
        </Box>
        <Box>
          <Box fontSize="20px" fontWeight={700} m="26px 0px 15px 0px">
            Passion
          </Box>
          <TextField
            multiline
            rows={4}
            fullWidth
            value={evaluationForm.passion}
            onChange={(e) => handleChange("passion", e.target.value)}
          />
        </Box>
        <Box>
          <Box fontSize="20px" fontWeight={700} m="26px 0px 15px 0px">
            Product-Market Fit
          </Box>
          <TextField
            multiline
            rows={4}
            fullWidth
            value={evaluationForm.product_market_fit}
            onChange={(e) => handleChange("product_market_fit", e.target.value)}
          />
        </Box>
        <Box>
          <Box fontSize="20px" fontWeight={700} m="26px 0px 15px 0px">
            Overall impression and recommendation
          </Box>
          <TextField
            multiline
            rows={4}
            fullWidth
            value={evaluationForm.overall_impression}
            onChange={(e) => handleChange("overall_impression", e.target.value)}
          />
        </Box>
        <Box>
          <Box fontSize="20px" fontWeight={700} m="26px 0px 15px 0px">
            Final recommendations
          </Box>
          <FilterByStatus
            value={evaluationForm.final_recommendation}
            onChange={(value) => handleChange("final_recommendation", value)}
            filterOptions={options}
            isDisabled={false}
          />
        </Box>
      </Box>
      <Box mt="30px">
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#2A41AB",
            "&:hover": { backgroundColor: "#2A41AB" },
            textTransform: "none",
            marginBottom: "5px",
            marginLeft: "5px",
            padding: "7px 70px",
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default EvaluationForm;
