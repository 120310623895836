import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { FaFilter } from "react-icons/fa";

const FilterByStatus = ({
  width,
  onChange,
  value,
  label = "",
  filterOptions,
 isDisabled=true
}) => {
  const adminType = localStorage.getItem("adminType");
let disabled=false
if(isDisabled)
{
  disabled= adminType === "Invigilators"
}
  const handleChange = (event) => {
    onChange(event?.target?.value);
  };

  return (
    <div>
      <FormControl
        fullWidth
        size="small"
        sx={{
          width: width,
          maxWidth: "100%",
          "& .MuiInputLabel-formControl": { marginTop: "8px" },
          "& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root": {
            top: "-8px",
          },
        }}
      >
        <InputLabel id="demo-select-small-label">
          {label ? (
            <>
              {" "}
              <FaFilter /> {label}{" "}
            </>
          ) : (
            label
          )}
        </InputLabel>
        <Select
          labelId="demo-select-small-label"
          disabled={disabled}
          id="demo-select-small"
          value={value}
          label={label ? `a ${label}` : label}
          onChange={handleChange}
          sx={{ textAlign: "left", p: label ? "7px" : "" }}
          displayEmpty={label ? false : true}
        >
          {adminType !== "Invigilators" && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {filterOptions?.map((item) => {
            return <MenuItem value={item.value}>{item.label}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default FilterByStatus;
