import React, { useState } from "react";
import ApplicantNav from "./ApplicantNav";
import { Box } from "@mui/material";
import BasicInformation from "./BasicInformation";
import InterviewInformation from "./InterviewInformation";
import RecordingInformation from "./RecordingInformation";
import FeedbackList from "./FeedbackList";
import EvaluationForm from "./EvaluationForm";

const SingleApplication = ({
  data,
  index,
  applicationList,
  setApplicationList,
  isOpen,
  handleClose,
  isEvaluation=false
}) => {
  const [activeNav, setActiveNav] = useState("basicInformation");

  return (
    <Box p={"20px"} style={{ position: "relative", zIndex: 100,background:"#FFFFFF" }}>
      <ApplicantNav
        activeNav={activeNav}
        setActiveNav={setActiveNav}
        index={index}
        userData={data}
        applicationList={applicationList}
        setApplicationList={setApplicationList}
        isOpen={isOpen}
        handleClose={handleClose}
        isEvaluation={isEvaluation}
      />
      {activeNav === "basicInformation" && <BasicInformation data={data} />}
      {activeNav === "interview" && <InterviewInformation data={data} />}
      {activeNav === "recording" && <RecordingInformation data={data} />}
      {activeNav=== "feedback" && <FeedbackList data={data} />}
      {activeNav=== "evaluation" && <EvaluationForm id={data?.id} />}
    </Box>
  );
};

export default SingleApplication;
