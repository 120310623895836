import { Box } from "@mui/material";
import React, { useState } from "react";
import FilterByStatus from "../uiComponents/FilterByStatus";
import EvaluationModal from "./EvaluationModal";

const options = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

const QuestionAnswer = ({ question, value, onChange, text }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Box
      backgroundColor="#F6F8FB"
      p="14px"
      borderRadius={"8px"}
      display={"flex"}
      justifyContent={"space-between"}
    >
      <EvaluationModal
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        heading={question}
        text={text}
      />
      <Box
        sx={{
          fontWeight: 600,
          color: "#333F51",
          fontSize: "16px",
          cursor: "pointer",
        }}
        onClick={() => setIsOpen(true)}
      >
        {question}
      </Box>
      <Box backgroundColor="#FFFFFF">
        <FilterByStatus
          value={value}
          onChange={onChange}
          filterOptions={options}
          isDisabled={false}
        />
      </Box>
    </Box>
  );
};

export default QuestionAnswer;
