import { Box, Tooltip } from "@mui/material";
import React, { useState } from "react";
import Pagination from "./Pagination";
import dayjs from "dayjs";
import { HiOutlineThumbDown } from "react-icons/hi";
import { HiOutlineThumbUp } from "react-icons/hi";
import { HiThumbDown } from "react-icons/hi";
import { HiThumbUp } from "react-icons/hi";
import { toast } from "react-toastify";
import { blacklistById, feedbackById } from "../services/user";
import { useQuery } from "../components/AllApplicationTable";
import { RiFlag2Fill, RiFlag2Line } from "react-icons/ri";
import { useLocation } from "react-router-dom";

const textStyle = {
  fontFamily: "Segoe UI",
  fontSize: "13px",
  fontWeight: 400,
  lineHeight: "17px",
  letterSpacing: "0.02em",
  textAlign: "left",
  color: "#272727",
  padding: "10px 15px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const iconParent = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
};

const countStyle = {
  color: "#FFFFFF",
  position: "absolute",
  right: "-5px",
  bottom: "0px",
  backgroundColor: "black",
  padding: "0px 4px",
  borderRadius: "50%",
  fontSize: "9px",
};

const interviewStatusStyle = {
  padding: "4px 6px 4px 8px",
  borderRadius: "40px",
  textAlign: "center",
  fontFamily: "Segoe UI",
  fontSize: "13px",
  fontWeight: 400,
  lineHeight: "17px",
  letterSpacing: "0.02em",
};

const EvaluationTable = ({
  handleApplicant,
  totalPages,
  data,
  handlePageChange,
  currentPage,
  setFeedbackCall,
  applicationList,
  setApplicationList,
  handleSortData,
  handleSortScore,
}) => {
  const query = useQuery();
  const approval = query.get("approval") ?? "";
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const unique = queryParams.get("unique");

  const handleFeedback = async (id, approved, disapproved) => {
    console.log(approved, disapproved);
    const adminId = localStorage.getItem("adminId");
    const newData = [...applicationList];
    const index = newData.findIndex((item) => item.id === id);
    const data = {
      admin_user_id: adminId,
      approval: approved,
      disapproval: disapproved,
    };
    const resp = await feedbackById(id, data);

    newData[index] = {
      ...newData[index],
      is_approved: approved,
      is_disapproved: disapproved,
    };
    setApplicationList(newData);
    setFeedbackCall((pre) => !pre);
    toast.success("feedback provided successfully!");
  };

  const feedbackUi = (
    id,
    approved,
    disapproved,
    likeCount,
    DislikeCount,
    likeNames,
    DislikeNames,
    blacklisted,
    data
  ) => {
    const adminType = localStorage.getItem("adminType") ?? "";
    likeNames = likeNames?.join(", ");
    DislikeNames = DislikeNames?.join(", ");
    if (blacklisted) {
      return;
    }
    if (adminType === "Superuser") {
      return (
        <div style={{ display: "flex", gap: "20px" }}>
          <Tooltip title={likeNames} placement="bottom-start" arrow>
            <span style={iconParent}>
              <span>
                <HiThumbUp
                  size={25}
                  color="#03C972"
                  style={{ cursor: "pointer" }}
                />
              </span>
              <span style={countStyle}>{likeCount}</span>
            </span>
          </Tooltip>
          <Tooltip title={DislikeNames} placement="bottom-start" arrow>
            <span style={iconParent}>
              <span>
                <HiThumbDown
                  size={25}
                  color="#FF483E"
                  style={{ cursor: "pointer" }}
                />
              </span>
              <span style={countStyle}>{DislikeCount}</span>
            </span>
          </Tooltip>
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex", gap: "20px" }}>
          {approved ? (
            <Tooltip title={likeNames} placement="bottom-start" arrow>
              <span style={iconParent}>
                <span>
                  <HiThumbUp
                    onClick={() =>
                      handleFeedback(
                        id,
                        approved === null
                          ? true
                          : approved === true
                          ? false
                          : true,
                        false
                      )
                    }
                    size={25}
                    color="#03C972"
                    style={{ cursor: "pointer" }}
                  />
                </span>
                <span style={countStyle}>{likeCount}</span>
              </span>
            </Tooltip>
          ) : (
            <Tooltip title={likeNames} placement="bottom-start" arrow>
              <span style={iconParent}>
                <span>
                  <HiOutlineThumbUp
                    color="#DCDDDD"
                    size={25}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleFeedback(
                        id,
                        approved === null
                          ? true
                          : approved === true
                          ? false
                          : true,
                        false
                      )
                    }
                  />
                </span>
                <span style={countStyle}>{likeCount}</span>
              </span>
            </Tooltip>
          )}
          {disapproved ? (
            <Tooltip title={DislikeNames} placement="bottom-start" arrow>
              <span style={iconParent}>
                <span>
                  <HiThumbDown
                    onClick={() =>
                      handleFeedback(
                        id,
                        false,
                        disapproved === null
                          ? true
                          : disapproved === true
                          ? false
                          : true
                      )
                    }
                    size={25}
                    color="#FF483E"
                    style={{ cursor: "pointer" }}
                  />
                </span>
                <span style={countStyle}>{DislikeCount}</span>
              </span>
            </Tooltip>
          ) : (
            <Tooltip title={DislikeNames} placement="bottom-start" arrow>
              <span style={iconParent}>
                <span>
                  <HiOutlineThumbDown
                    color="#DCDDDD"
                    size={25}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleFeedback(
                        id,
                        false,
                        disapproved === null
                          ? true
                          : disapproved === true
                          ? false
                          : true
                      )
                    }
                  />
                </span>
                <span style={countStyle}>{DislikeCount}</span>
              </span>
            </Tooltip>
          )}
        </div>
      );
    }
  };

  const handleBlacklistApplication = async (id) => {
    const adminType = localStorage.getItem("adminType") ?? "";
    if (adminType !== "Superuser") {
      const resp = await blacklistById(id);
      toast.success("Applicant marked as Wrong Applicant!");
      setFeedbackCall((pre) => !pre);
    }
  };

  return (
    <Box
      sx={{
        width: "82vw",
        maxHeight: "65vh",
        borderLeft: "1px solid #929EAE",
        borderBottom: "1px solid #929EAE",
        borderRight: "1px solid #929EAE",
        position: "relative",
      }}
    >
      <Box
        sx={{
          maxHeight: "75vh",
          overflow: "auto",
          borderBottom: "1px solid #929EAE",
        }}
      >
        <table
          style={{
            width: "100%",
            textAlign: "left",
            borderCollapse: "collapse",
          }}
        >
          <thead
            style={{
              backgroundColor: "#DEE4FF",
              color: "#141414",
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <tr>
              <th
                style={{
                  ...textStyle,

                  color: "#141414",
                  textAlign: "center",
                  fontWeight: 600,
                }}
                width="5%"
              >
                Sr No
              </th>
              <th
                style={{
                  ...textStyle,

                  color: "#141414",
                  textAlign: "center",
                  fontWeight: 600,
                }}
                width="5%"
              >
                Unique Id
              </th>
              <th
                scope="col"
                style={{ ...textStyle, color: "#141414", fontWeight: 600 }}
                width="25%"
              >
                Applicant Name
              </th>

              <th
                scope="col"
                style={{
                  ...textStyle,
                  color: "#141414",
                  textAlign: "center",
                  fontWeight: 600,
                }}
                width="15%"
              >
                Evaluation Status
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((application, index) => (
                <tr
                  key={application?.id}
                  style={{
                    borderBottom: "1px solid #929EAE",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <td
                    style={{
                      ...textStyle,

                      padding: "5px 15px",
                      color: "#272728",
                      textAlign: "center",
                    }}
                  >
                    {currentPage !== 1
                      ? index + 1 + (currentPage - 1) * 50
                      : index + 1}
                  </td>
                  <td style={{ ...textStyle, textAlign: "center" }}>
                    {application.user_id ?? "--"}
                  </td>
                  <td
                    style={{
                      ...textStyle,

                      color: "#043A87",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleApplicant(application?.user_id, application)
                    }
                  >
                    {application?.firstname ?? "--"} {application?.lastname}
                  </td>

                  <td
                    style={{
                      ...textStyle,

                      padding: "5px 15px",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        ...interviewStatusStyle,
                        color: application?.status ? "#03C93B" : "#D2A83A",
                        backgroundColor: application?.status
                          ? "#E6FAEB"
                          : "#F9F4E6",
                      }}
                    >
                      {application?.status
                        ? "Evaluation Done"
                        : "Evaluation Pending"}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr
                style={{
                  borderBottom: "1px solid #929EAE",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <td
                  align="center"
                  colSpan={10}
                  style={{ padding: "12px 24px", color: "#272728" }}
                >
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>
      <Pagination
        currentDataLength={data?.length}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePage={(value) => handlePageChange(value)}
      />
    </Box>
  );
};

export default EvaluationTable;
