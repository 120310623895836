import { Box } from "@mui/material";
import React, { useContext } from "react";
import { useState } from "react";
import { TiDelete } from "react-icons/ti";
import AllEvaluationTable from "../components/AllEvaluationTable";
import SingleApplication from "../components/SingleApplication";
import { getInterviewDetailsById, getUserDetailsById } from "../services/user";
import { toast } from "react-toastify";
import ExportToExcel from "../uiComponents/ExportToExcel";
import { UserContext } from "../context/UserContext";

const tabStyle = {
  borderTop: "1px solid #929EAE",
  borderLeft: "1px solid #929EAE",
  borderRight: "1px solid #929EAE",
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
  p: "10px 20px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Segoe UI",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "18px",
  letterSpacing: "0.02em",
  textAlign: "center",
};

const Evaluations = () => {
  const [applicationList, setApplicationList] = useState([]);
  const [totalUser, setTotalUser] = useState(0);
  const [activeTab, setActiveTab] = useState(-1);
  const [error, setError] = useState(false);
  const { switchTab } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);

  const handleApplicant = async (id, tableData) => {
    console.log("id", id, "tableData", tableData);
    if (applicationList.length >= 5) {
      toast.error("Please remove tab before adding!");
      return;
    }
    const index = applicationList.findIndex((obj) => obj.id === id);
    if (index !== -1) {
      setActiveTab(index);
    } else {
      const resp1 = await getUserDetailsById(id);
      const resp2 = await getInterviewDetailsById(id);
      console.log(resp1, resp2);
      const approved_invigilators = tableData.approved_invigilators ?? [];
      const disapproved_invigilators = tableData.disapproved_invigilators ?? [];
      setApplicationList((list) => [
        ...list,
        { ...resp1, ...resp2, approved_invigilators, disapproved_invigilators },
      ]);
      setActiveTab(applicationList?.length);
    }
  };

  const handleDelete = (event, index, id) => {
    if (switchTab.includes(id)) {
      setIsOpen(true);
      return;
    }
    event.stopPropagation(); // Prevents event from bubbling up to the div
    const newArray = [...applicationList];
    newArray.splice(index, 1); // will remove the object from array
    setApplicationList(newArray);
    setActiveTab(-1); // will show table
  };

  if (error) {
    return <div style={{ textAlign: "center", marginTop: "100px" }}>Error</div>;
  }

  return (
    <Box sx={{ m: "30px" }}>
      {/* <FeedbackPopup open={isOpen} handleClose={()=>setIsOpen(false)}  /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingRight: "15px",
        }}
      >
        <Box sx={{ display: "flex", gap: "5px" }}>
          <Box
            sx={{
              ...tabStyle,
              backgroundColor: activeTab === -1 ? "#FFFFFF" : "#DEE0E3",
              cursor: "pointer",
            }}
            onClick={() => {
              // if (!switchTab) {
              setActiveTab(-1);
              // } else {
              //   navigationAlert();
              // }
            }}
          >
            All applicants
            <span
              style={{
                color: "#FFFFFF",
                backgroundColor: "#2A41AB",
                fontFamily: "ABeeZee",
                fontSize: "14px",
                fontWeight: 400,
                padding: "2px 5px",
                borderRadius: "10px",
                marginLeft: "5px",
              }}
            >
              {totalUser}
            </span>
          </Box>
          {applicationList?.map((item, index) => {
            return (
              <Box
                key={item?.id}
                sx={{
                  ...tabStyle,
                  backgroundColor: activeTab === index ? "#FFFFFF" : "#DEE0E3",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setActiveTab(index);
                }}
              >
                {item?.firstname ?? "User"} {item.lastname ?? ""}
                <TiDelete
                  onClick={(event) => {
                    handleDelete(event, index, item?.id);
                  }}
                  color="#F1554C"
                  size={26}
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                />
              </Box>
            );
          })}
        </Box>
        {/* <ExportToExcel /> */}
      </Box>
      {activeTab === -1 ? (
        <AllEvaluationTable
          handleApplicant={handleApplicant}
          setTotalUser={setTotalUser}
          setError={setError}
          applicationList={applicationList}
          setApplicationList={setApplicationList}
        />
      ) : (
        applicationList?.map((data, index) => {
          return (
            activeTab === index && (
              <SingleApplication
                key={data?.id}
                data={data}
                index={index}
                applicationList={applicationList}
                setApplicationList={setApplicationList}
                isOpen={isOpen}
                handleClose={() => setIsOpen(false)}
                isEvaluation={true}
              />
            )
          );
        })
      )}
    </Box>
  );
};

export default Evaluations;
