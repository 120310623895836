import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import { RxCross1 } from "react-icons/rx";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  backgroundColor: "#FFFFFF",
  outline: "none",
  borderRadius: "10px",
  p: "16px 30px 30px 30px",
};

const EvaluationModal = ({ open, handleClose, heading, text }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography textAlign={"right"}>
          <RxCross1
            style={{ cursor: "pointer" }}
            size={24}
            onClick={handleClose}
          />
        </Typography>
        <Typography
          sx={{
            mt: "15px",
            backgroundColor: "#E8EEFF",
            p: "6px 18px",
            borderRadius: "8px",
            fontfamily: "Segoe UI",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "21px",
            textAlign: "left",
            color: "#333F51",
            display: "flex",
            gap: "5px",
            alignItems: "flex-start",
          }}
        >
          <div>{heading}</div>
        </Typography>
        <Typography
          sx={{
            mt: "15px",
            fontfamily: "Segoe UI",
            fontSize: "15px",
            fontWeight: 400,
            lineHeight: "19px",
            textAlign: "justify",
            // height: "300px",
            overflow: "auto",
            paddingRight: "5px",
          }}
        >
          {text}
        </Typography>
      </Box>
    </Modal>
  );
};

export default EvaluationModal;
