import React, { useContext } from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FlexBetween from "./FlexBetween";
import { FaUserFriends } from "react-icons/fa";
import { HiViewGrid } from "react-icons/hi";
import isbIcon from "../assets/isbIcon.png";
import iviLogo from "../assets/iviLogo.png";
import { UserContext } from "../context/UserContext";
import { toast } from "react-toastify";
import esmagicoLogo from "../assets/esmagicoLogo.png";
import { RiNumbersFill } from "react-icons/ri";
import { FaNoteSticky } from "react-icons/fa6";
export const navigationAlert = () => {
  toast.error("Please provide feedback before navigating to another tab.");
};

const navItems = [
  {
    text: "Overview",
    icon: <HiViewGrid size={20} />,
    url: "overview",
  },
  {
    text: "Applicants",
    icon: <FaUserFriends size={20} />,
  },
  {
    text: "All Applicants",
    url: "applicants",
  },
  {
    text: "Unique Number",
    icon: <RiNumbersFill size={20} />,
    url: "Unique-Numbers",
  },
  {
    text: "Evaluation",
    icon: <FaNoteSticky size={20} />,
    url: "evaluations",
  },
];

const Sidebar = ({ drawerWidth, isNonMobile }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { switchTab } = useContext(UserContext);
  const adminType = localStorage.getItem("adminType");

  return (
    <Box component="nav">
      <Drawer
        open={true}
        variant="persistent"
        anchor="left"
        sx={{
          width: drawerWidth,
          "& .MuiDrawer-paper": {
            color: "white",
            background: " linear-gradient(180deg, #4A5EC4 0%, #001374 100%)",

            position: "fixed",
            boxSixing: "border-box",
            borderWidth: isNonMobile ? 0 : "2px",
            width: drawerWidth,
            height: "100vh",
          },
        }}
      >
        <Box sx={{ p: "23px" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <img
              src={isbIcon}
              alt="isb-logo"
              style={{ width: "51px", height: "19px", marginBottom: "15px" }}
            />
            <img
              src={iviLogo}
              alt="ivi-logo"
              style={{ width: "167px", height: "20px" }}
            />
          </Box>
        </Box>
        <Box>
          <div
            className="logo-container"
            style={{
              width: "100%",
              height: 62,
              margin: "-50px 0px",
              position: "absolute",
            }}
          >
            <Box m="0.5rem 1rem 0rem 2rem">
              <FlexBetween>
                <Box>
                  {/* <img style={{ margin: "20px 10px" }} src={logoSVG} alt="" /> */}
                  {/* <Typography
                    // variant="h4"
                    fontSize="31px"
                    fontStyle="italic"
                    // fontWeight="bold"
                    fontFamily="sans-serif"
                    marginTop="10px"
                  >
                    Gratify
                  </Typography>
                  <Box
                    display="flex"
                    alignItems={"center"}
                    // fontSize="10px"
                    fontWeight="normal"
                    gap="2px"
                    paddingLeft="10px"
                  >
                    <Box component="span" fontFamily="Outfit" fontWeight={600} variant="Cap height" fontSize="12px" >By</Box>
                    <img width="84px" src={EsMagicoLogo} alt="logo" />
                  </Box> */}
                </Box>
                {!isNonMobile && (
                  <IconButton
                    onClick={() =>
                      console.log(" setIsSidebarOpen(!isSidebarOpen)")
                    }
                  >
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
          </div>

          <List>
            {navItems?.map(({ text, icon, url }) => {
              if(adminType !== "Invigilators" && text === "Evaluation")
              {
                return null
              }else if (adminType === "Invigilators" && text !== "Evaluation") {
                return null;
              }

              if (!icon) {
                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        if (switchTab && switchTab?.length === 0) {
                          navigate(`/${url}`);
                        }
                      }}
                      sx={{
                        height: "35px",
                        backgroundColor: pathname.includes(url)
                          ? "rgba(255, 255, 255, 0.30)"
                          : "transparent",
                        color: "white",
                      }}
                    >
                      <FiberManualRecordIcon sx={{ fontSize: 8, ml: "35px" }} />
                      <ListItemText primary={text} sx={{ ml: "5px" }} />
                    </ListItemButton>
                  </ListItem>
                );
              }

              return (
                <ListItem
                  key={text}
                  disablePadding
                  sx={{ m: "1.5rem 0rem 0rem 0rem" }}
                >
                  <ListItemButton
                    onClick={() => {
                      if (switchTab && switchTab?.length === 0 && url) {
                        navigate(`/${url}`);
                      } else {
                        if (url) navigationAlert();
                      }
                    }}
                    sx={{
                      height: "35px",
                      backgroundColor: pathname.includes(url)
                        ? "rgba(255, 255, 255, 0.30)"
                        : "transparent",
                      color: "white",
                      cursor: url ? "pointer" : "context-menu",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        ml: "0.5rem",
                        color: "white",
                        minWidth: "35px",
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                    {/* {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )} */}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>

        <Box position="absolute" bottom="2rem">
          <Divider />
        </Box>
        <Box sx={{ position: "absolute", bottom: "50px", paddingLeft: "23px" }}>
          <div
            style={{
              fontFamily: "Segoe UI",
              fontSize: "15px",
              fontWeight: 600,
              lineHeight: "19px",
              textAlign: "left",
              marginBottom: "12px",
            }}
          >
            Powered By
          </div>
          <a href="https://www.esmagico.in/" target="_blank" rel="noreferrer">
            <img src={esmagicoLogo} alt="logo" />{" "}
          </a>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
