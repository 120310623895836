import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import SearchField from "../uiComponents/SearchField";
import FilterByStatus from "../uiComponents/FilterByStatus";
import { Box, CircularProgress } from "@mui/material";
import CommonDateFilterPanel from "./filter/CommonDateFilterPanel";
import { filterSelectOptions } from "../pages/Overview";
import {
  getEvaluationDashboardDetails,
  getUserDashboardDetails,
} from "../services/user";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";
import EvaluationTable from "../uiComponents/EvaluationTable";
import CustomDatePicker from "./CustomDatePicker";

export const statusOptions = [
  {
    label: "Data not Filled",
    value: "0",
  },
  {
    label: "Personal Details Filled",
    value: "1",
  },
  {
    label: "Academic Details Filled",
    value: "2",
  },
  {
    label: "Video Submitted",
    value: "3",
  },
  {
    label: "Payment Done and Interview Link Sent",
    value: "5",
  },
  {
    label: "Interview Done",
    value: "6",
  },
  {
    label: "Wrong Applications",
    value: "7",
  },
];

export const uniqueStatusOptions = [
  {
    label: "Data not Filled",
    value: "0",
  },
  {
    label: "Personal Details Not Filled",
    value: "1",
  },
  {
    label: "Academic Details Not Filled",
    value: "2",
  },
  {
    label: "Video Not Submitted",
    value: "3",
  },
  {
    label: "Payment Not Done ",
    value: "5",
  },
  {
    label: "Interview Not Done",
    value: "6",
  },
];

export const approvalOptions = [
  {
    label: "2+ Evaluators",
    value: "multiple_approval",
  },
  {
    label: "1 Evaluator",
    value: "single_approval",
  },
  {
    label: "Not Evaluated",
    value: "no_approval",
  },
  {
    label: "Not Approved",
    value: "disapproved",
  },
];

export const scoreOptions = [
  {
    label: "Greater Than 40",
    value: "greater_than_40",
  },
  {
    label: "Less Than 40",
    value: "less_than_40",
  },
];

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const AllEvaluationTable = ({
  handleApplicant,
  setTotalUser,
  setError,
  applicationList,
  setApplicationList,
}) => {
  const adminType = localStorage.getItem("adminType");
  const options = [
    {
      label: "Evaluation Pending",
      value: "False",
    },
    {
      label: "Evaluation Done",
      value: "True",
    },
  ];

  const initialDateFilter = () => {
    return {
      select: "ALL_TIME",
      from_time: dayjs(),
      name: "",
      filterBy: "",
      page: "1",
      filterByApproval: "",
      filterByScore: "",
    };
  };

  const [dateFilter, setDateFilter] = useState(initialDateFilter);
  const [data, setData] = useState({});
  const [inputDate, setInputDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [feedbackCall, setFeedbackCall] = useState(false);
  const query = useQuery();
  const filter = query.get("filter");
  const approval = query.get("approval") ?? "";
  const wrongApplication = query.get("wrong-application") ?? "";
  const selected = query.get("selected") ?? "";
  const [scoreSort, setScoreSort] = useState("lth");
  const [dateSort, setDataSort] = useState("lth");

  const handleSortScore = () => {
    const sortedData = [...data?.data]?.sort((a, b) => {
      if (scoreSort === "lth") {
        setScoreSort("htl");
        return a.total_score - b.total_score;
      } else {
        setScoreSort("lth");
        return b.total_score - a.total_score;
      }
    });
    setData((pre) => {
      return { ...pre, data: sortedData };
    });
  };

  const handleSortData = () => {
    const sortedData = [...data?.data].sort((a, b) => {
      const dateA = new Date(a["interview_time"]);
      const dateB = new Date(b["interview_time"]);

      if (dateSort === "lth") {
        setDataSort("htl");
        return dateA - dateB;
      } else {
        setDataSort("lth");
        return dateB - dateA;
      }
    });
    setData((pre) => {
      return { ...pre, data: sortedData };
    });
  };

  const handleDateFilter = (name, value) => {
    setDateFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUserDashBoardDetails = async () => {
    const date = dateFilter?.from_time ?? dayjs();
    const search = dateFilter?.name ?? "";
    const status = dateFilter?.filterBy;
    const page = dateFilter?.page ?? "";
    console.log("status", status);
    const resp = await getEvaluationDashboardDetails(
      date,
      search,
      status,
      page,
      setLoading,
      setError
    );
    console.log("resp====", resp);
    setData(resp);
    setLoading(false);
    setTotalUser(resp?.total_count);
  };

  // Memoize the debounced function
  const debouncedHandleUserDashBoardDetails = useMemo(
    () => debounce(handleUserDashBoardDetails, 300),
    [dateFilter]
  );

  useEffect(() => {
    debouncedHandleUserDashBoardDetails();
    // Cleanup function to cancel the debounce if the component unmounts or effect re-runs
    return () => {
      debouncedHandleUserDashBoardDetails.cancel();
    };
  }, [dateFilter, debouncedHandleUserDashBoardDetails, feedbackCall]);

  useEffect(() => {
    if (filter) {
      handleDateFilter("page", "1");
      handleDateFilter("filterBy", filter);
    } else if (approval) {
      handleDateFilter("page", "1");

      if (approval == 4) {
        handleDateFilter("filterByApproval", "no_approval");
        return;
      }
      if (approval == 3) {
        handleDateFilter("filterByApproval", "disapproved");
        return;
      }
      if (approval == 1) {
        handleDateFilter("filterByApproval", "single_approval");
      } else {
        handleDateFilter("filterByApproval", "multiple_approval");
      }
    } else if (wrongApplication) {
      handleDateFilter("page", "1");
      handleDateFilter("filterBy", "7");
    } else if (selected) {
      handleDateFilter("page", "1");
      console.log("selected12" + selected);
      handleDateFilter("filterBy", "8");
    }
  }, [filter, approval, wrongApplication, selected]);

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "150px" }}>
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <Box
      sx={{
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          p: "10px 8px",
          borderTop: "1px solid #929EAE",
          borderLeft: "1px solid #929EAE",
          borderRight: "1px solid #929EAE",
          width: "81vw",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <SearchField
            width={"400px"}
            value={dateFilter?.name}
            onChange={(value) => {
              handleDateFilter("page", "1");
              handleDateFilter("name", value);
            }}
          />
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
            }}
          >
            <CustomDatePicker
              name={"from_time"}
              style={{ height: "50px" }}
              // maxDate={dateFilter?.to_time ? dayjs(dateFilter?.to_time) : null}
              value={dateFilter.from_time ? dayjs(dateFilter.from_time) : null}
              onChange={(name, value) => handleDateFilter(name, value)}
              label={"Filter by date"}
            />
            <FilterByStatus
              width={"200px"}
              value={dateFilter?.filterBy}
              label="Filter by status"
              onChange={(value) => {
                handleDateFilter("page", "1");
                handleDateFilter("filterBy", value);
              }}
              filterOptions={options}
              isDisabled={false}
            />

          </Box>
        </Box>
      </Box>
      <EvaluationTable
        handleApplicant={handleApplicant}
        data={data?.grouped_feedback[0]?.feedbacks}
        totalPages={data.totalpages}
        currentPage={dateFilter?.page}
        handlePageChange={(value) => handleDateFilter("page", value)}
        setFeedbackCall={setFeedbackCall}
        applicationList={applicationList}
        setApplicationList={setApplicationList}
        handleSortData={handleSortData}
        handleSortScore={handleSortScore}
      />
    </Box>
  );
};

export default AllEvaluationTable;
