import axios from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";

// const queryParams = new URLSearchParams(window.location.search);
// const unique = queryParams.get("unique");

export const login = async (values) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/admin/adminlogin/`,
    values
  );

  return response?.data;
};

export const forgotpassword = async (values) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/admin/forgotpassword/`,
    values
  );

  return response?.data;
};

export const proctoringTest = async (values) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/proctor/check-video/`,
    values
    // {
    //   onUploadProgress: (progressEvent) => {
    //     const total = progressEvent.total;
    //     const current = progressEvent.loaded;
    //     const percentCompleted = Math.round((current / total) * 100);
    //     setProgress(percentCompleted);
    //   },
    // }
  );

  return response?.data;
};

export const resetPassword = async (values) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/admin/updatepassword/`,
    values
  );

  return response?.data;
};

export const getAllUsersDetails = async (
  startDate,
  endDate,
  setLoading=()=>{},
  setError=()=>{}
) => {
  startDate = startDate ? dayjs(startDate).format("YYYY-MM-DD") : "";
  endDate = endDate ? dayjs(endDate).format("YYYY-MM-DD") : "";

  try {
    // const response = await axios.get(
    //   `${process.env.REACT_APP_API_BASE_URL}/api/admin/alluserdetails/?startdate=${startDate}&enddate=${endDate}&selected=1`
    // );
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/admin/alluserdetails/?startdate=${startDate}&enddate=${endDate}`
    );
    return response?.data;
  } catch (e) {
    console.log("error while fetching get user details API:=", e?.message);
    setLoading(false);
    setError(true);
  }
};

export const getAllUsersDailyReport = async (
  startDate,
  endDate,
) => {
  startDate = startDate ? dayjs(startDate).format("YYYY-MM-DD") : "";
  endDate = endDate ? dayjs(endDate).format("YYYY-MM-DD") : "";

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/admin/user-daily-report/?startdate=${startDate}&enddate=${endDate}`
    );
    return response?.data;
  } catch (e) {
    console.log("error while fetching get user daily report API:=", e?.message);
  }
};

export const getAllUsersDetailsUnique = async (
  startDate,
  endDate,
  setLoading,
  setError
) => {
  startDate = startDate ? dayjs(startDate).format("YYYY-MM-DD") : "";
  endDate = endDate ? dayjs(endDate).format("YYYY-MM-DD") : "";

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/admin/alluserdetailsunique/?startdate=${startDate}&enddate=${endDate}`
    );

    return response?.data;
  } catch (e) {
    console.log("error while fetching get user details API:=", e?.message);
    setLoading(false);
    setError(true);
  }
};

export const getUserDashboardDetails = async (
  startDate,
  endDate,
  search,
  status,
  page,
  setLoading,
  setError,
  singleApproval = "",
  multiApproval = "",
  noApproval = "",
  disapproved = "",
  lessThan40 = "",
  greaterThan40 = "",
  wrongApplications = "",
  unique,
  selected = ""
) => {
  startDate = startDate ? dayjs(startDate).format("YYYY-MM-DD") : "";
  endDate = endDate ? dayjs(endDate).format("YYYY-MM-DD") : "";
  const adminId = localStorage.getItem("adminId");
  console.log("unique", unique);
  let apipath = "alluserdashboarddetails";
  if (unique) {
    apipath = "alluserdashboarddetailsunique";
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/admin/${apipath}/?admin_id=${adminId}&startdate=${startDate}&enddate=${endDate}&search=${search}&status=${status}&page=${page}&single_approval=${singleApproval}&multiple_approvals=${multiApproval}&no_approval=${noApproval}&disapproved=${disapproved}&score_gt40=${greaterThan40}&score_lt40=${lessThan40}&wrong_applications=${wrongApplications}&selected=${selected}`
    );

    return response?.data;
  } catch (e) {
    console.log(
      "error while fetching get user dashboard details API:=",
      e?.message
    );
    setError(true);
    setLoading(false);
  }
};

export const getEvaluationDashboardDetails = async (
  date,
  search,
  status,
  page,
  setLoading,
  setError
) => {
  date = date ? dayjs(date).format("YYYY-MM-DD") : "";
  let dateParam = "";
  let nameParam = "";
  let statusParam = "";

  if (date) {
    dateParam = `&date=${date}`;
  }
  if (search) {
    nameParam = `&name=${search}`;
  }
  if (status !== "") {
    statusParam = `&status=${status.toString()}`;
  }
  const adminId = localStorage.getItem("adminId");

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/user/onsite-interview-feedback/?admin_id=${adminId}&page_size=50&page=${page}${dateParam}${nameParam}${statusParam}`
    );

    return response?.data;
  } catch (e) {
    console.log(
      "error while fetching get user dashboard details API:=",
      e?.message
    );
    setError(true);
    setLoading(false);
  }
};

export const getExcelDetails = async (
  startDate,
  endDate,
  filter,
  setLoading,
  singleApproval = "",
  multiApproval = "",
  disapproved = "",
  noApproval = "",
  cityFilter = "",
  unique = ""
) => {
  startDate = startDate ? dayjs(startDate).format("YYYY-MM-DD") : "";
  endDate = endDate ? dayjs(endDate).format("YYYY-MM-DD") : "";

  let apipath = "getuserdetailsreport";
  if (unique) {
    apipath = "getuserdetailsreportunique";
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/admin/${apipath}/?startdate=${startDate}&enddate=${endDate}&application_status=${filter}&multiple_approvals=${multiApproval}&single_approval=${singleApproval}&no_approval=${noApproval}&disapproved=${disapproved}&city=${cityFilter}`
    );

    return response?.data;
  } catch (e) {
    console.log(
      "error while fetching get user excel details API:=",
      e?.message
    );
    toast.error("Something went wrong please try again!");
    setLoading(false);
  }
};

export const getUserDetailsById = async (id) => {
  const adminId = localStorage.getItem("adminId");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/admin/getuserdetail/${id}/?admin_id=${adminId}`
    );

    return response?.data;
  } catch (e) {
    console.log(
      "error while fetching get user details by id API:=",
      e?.message
    );
  }
};

export const getInterviewDetailsById = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/admin/getinterviewdetails/${id}/`
    );

    return response?.data;
  } catch (e) {
    console.log("error while fetching get interview details API:=", e?.message);
  }
};

export const postEvaluationForm = async (data) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/user/onsite-interview-feedback/`,data
    );
    return response?.data;
};

export const getEvaluationReport = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/user/report-onsite-interview-feedback/`
    );

    return response?.data?.report_data;
  } catch (e) {
    console.log("error while fetching  evaluation report Data API:=", e?.message);
  }
};

export const feedbackById = async (id, data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/admin/approvals/${id}/`,
      data
    );

    return response?.data;
  } catch (e) {
    console.log("error while fetching get interview details API:=", e?.message);
  }
};

export const blacklistById = async (id) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/admin/blacklist-applicant/`,
      { user_id: id }
    );

    return response?.data;
  } catch (e) {
    console.log("error while fetching blacklist application API:=", e?.message);
  }
};

export const getCity = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/admin/get-cities/`
    );

    return response?.data;
  } catch (e) {
    console.log("error while fetching get city API:=", e?.message);
  }
};

export const getVideos = async (id, type) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/user/generate_presigned_url/?candidate_id=${id}&recording_type=${type}`
  );

  return response?.data;
};
