import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import CustomDatePicker from "../components/CustomDatePicker";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { getAllUsersDailyReport } from "../services/user";
import { useLocation } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  backgroundColor: "#FFFFFF",
  outline: "none",
  borderRadius: "10px",
  p: "30px 50px",
};

const DownloadDailyReport = ({ open, handleClose, setLoading }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const unique = queryParams.get("unique");
  const today = dayjs();
  const beforeMonth = today.subtract(1, "month");
  const [filterDate, setFilterDate] = useState({
    toDate: today,
    fromDate: beforeMonth,
  });

  const handleExcel = async () => {
    if (!filterDate?.toDate || !filterDate?.fromDate) {
      toast.error("Please select a date first");
      return;
    }
    try {
      setLoading(true);
      const resp = await getAllUsersDailyReport(
        filterDate?.fromDate,
        filterDate?.toDate
      );

      if (!resp || !resp?.detail || resp?.detail?.length <= 0) {
        toast.error("No data available!");
        setLoading(false);
        return;
      }

      const data = resp?.detail?.map((item) => {
        const date = Object.keys(item)[0]; // Get the date key
        const details = item[date]; // Access the inner object
        return {
          Date: date,
          "Total Logins": details?.total_logins,
          "Data Not Filled": details?.data_not_filled,
          "Personal Details Filled": details?.personal_details_filled,
          "Academic Details Filled": details?.academic_details_filled,
          "Video Submitted": details?.video_submitted,
          "Payment Done": details?.payment_done,
          "Interview Done": details?.interview_done,
        };
      });

      // Convert object to an array of objects for Excel compatibility
      const processedData = data;

      console.log("Excel Data:", processedData);

      // Convert JSON data to worksheet
      const worksheet = XLSX.utils.json_to_sheet(processedData);
      console.log("Worksheet:", worksheet);

      // Create a new workbook
      const workbook = XLSX.utils.book_new();

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Write the workbook to a binary string
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Save the blob as an Excel file
      saveAs(blob, "Daily-Report.xlsx");
      handleClose();
      setLoading(false);
    } catch (error) {
      console.error("Error downloading the file:", error);
      toast.error("Something went wrong. Please try again");
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography
          sx={{
            fontFamily: "Segoe UI",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontfamily: "Segoe UI",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "26px",
            textAlign: "left",
            color: "#333F51",
            marginBottom: "20px",
          }}
          variant="h6"
          component="h2"
        >
          EXPORT AS EXCEL
          <RxCross1
            style={{ cursor: "pointer" }}
            size={24}
            onClick={handleClose}
          />
        </Typography>
        <Typography
          sx={{
            fontFamily: "Segoe UI",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
            textAlign: "left",
            color: "#212121",
            marginBottom: "5px",
          }}
        >
          Select a date range
        </Typography>
        <Typography
          sx={{
            fontFamily: "Segoe UI",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            textAlign: "left",
            color: "#7D7D7D",
            marginBottom: "15px",
          }}
        >
          The exported Excel sheet for the daily report will reflect the
          selected date range .
        </Typography>

        <Box sx={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
          <Box sx={{ width: "100%", overflow: "none" }}>
            <Typography
              sx={{
                fontFamily: "Segoe UI",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "left",
                color: "#7D7D7D",
              }}
            >
              From
            </Typography>
            <CustomDatePicker
              style={{ width: "100%" }}
              name={"from_time"}
              maxDate={filterDate?.toDate ? dayjs(filterDate?.toDate) : null}
              value={filterDate?.fromDate ? dayjs(filterDate?.fromDate) : null}
              onChange={(name, value) => {
                setFilterDate((pre) => {
                  return {
                    toDate: null,
                    fromDate: value,
                  };
                });
              }}
              label={""}
            />
          </Box>
          <Box sx={{ width: "100%", overflow: "none" }}>
            <Typography
              sx={{
                fontFamily: "Segoe UI",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "left",
                color: "#7D7D7D",
              }}
            >
              To
            </Typography>
            <CustomDatePicker
              style={{ width: "100%" }}
              name={"to_time"}
              minDate={
                filterDate?.fromDate ? dayjs(filterDate?.fromDate) : null
              }
              value={filterDate?.toDate ? dayjs(filterDate?.toDate) : null}
              onChange={(name, value) => {
                setFilterDate((pre) => {
                  return {
                    ...pre,
                    toDate: value,
                  };
                });
              }}
              label={""}
            />
          </Box>
        </Box>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#2A41AB",
            "&:hover": { backgroundColor: "#2A41AB" },
            textTransform: "none",
          }}
          onClick={handleExcel}
        >
          Export now
        </Button>
      </Box>
    </Modal>
  );
};

export default DownloadDailyReport;
